@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .animation-delay-2000 {
        animation-delay: 2s;
    }
    .animation-delay-6000 {
        animation-delay: 6s;
    }
}

.font-montserrat {
    font-family: "montserrat";
  }